import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/fonts.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { firebaseConfig } from './config/firebase';
import { LoadingSpinner } from './components/Loading';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner/>}>
      <App app={app} db={db}/>
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
