export const FILTER = {
    ALL: 'all',
    ENSEMBLE: 'ens',
    ORCHESTRA: 'orq',
    PIANO: 'pia',
    DISPLAY: {
        all: 'Todos',
        ens: 'Ensemble',
        orq: 'Orquesta',
        pia: 'Piano'
    }
}