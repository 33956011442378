import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef } from "react"

const AnimatedButton = ({ iconClass, onClick = () => {} }) => {

    const faIcon = useRef()

    const handleClick = () => {
        faIcon.current.classList.add('fa-animate-once')
        onClick()
        setTimeout(() => {
            faIcon.current.classList.remove('fa-animate-once')
        }, 500)
    }

    return(
        <FontAwesomeIcon ref={faIcon} icon={iconClass} style={{margin: 'auto 0', cursor: 'pointer'}} size="2x" onClick={handleClick}/>
    )
}

export default AnimatedButton