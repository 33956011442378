import { useEffect, useState } from 'react';
import FiniteScroll from './components/FiniteScroll';
import './styles/main.css';
import { getArragements } from './actions/get';
import { suspensify } from './utils';
import { FILTER } from './defaults';
import { LoadingSpinner } from './components/Loading';

const App = ({ db }) => {
    const [filter, setFilter] = useState(FILTER.ALL)
    const [promise, setPromise] = useState()
    const arragementList = promise ? promise?.read() : undefined;
    
    const filters = [FILTER.ALL, FILTER.ORCHESTRA, FILTER.ENSEMBLE, FILTER.PIANO]
    const active = [
        filter === FILTER.ALL,
        filter === FILTER.ORCHESTRA,
        filter === FILTER.ENSEMBLE,
        filter === FILTER.PIANO
    ]
    const qty = [
        arragementList?.length,
        arragementList?.filter(obj => obj.category === FILTER.ORCHESTRA).length,
        arragementList?.filter(obj => obj.category === FILTER.ENSEMBLE).length,
        arragementList?.filter(obj => obj.category === FILTER.PIANO).length,
    ]

    useEffect(() => {
        setPromise(suspensify(getArragements(db)))
    }, [db])

    return (arragementList ? <>
        <p className="title-top typed-out">PORTFOLIO</p>
            <h2 className="title-bottom">arodriguezmusic.site</h2>
            <hr/>
        <div className="select-carousel">
            <ul>
                {active.map((boolean,key) => (
                    <li key={key} className={`${boolean?'active':''}`} onClick={() => setFilter(filters[key])}>{FILTER.DISPLAY[filters[key]]}{boolean && <sub>{qty[key]}</sub>}</li>    
                ))}
            </ul>
        </div>
        <FiniteScroll filter={filter} list={arragementList}/>
    </> : <LoadingSpinner/>);
}

export default App;
