import React, { useState } from "react"
import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const VolumeButton = ({ toggleStatus = () => {} }) => {

    const [playing, setPlaying] = useState(false)

    const playingCallback = (status) => {
        setPlaying(status)
        toggleStatus(status)
    }

    return(playing ? 
        <FontAwesomeIcon style={{margin: 'auto 0', cursor: 'pointer'}} icon={faVolumeHigh} size="2x" onClick={() => playingCallback(false)}/> : 
        <FontAwesomeIcon style={{margin: 'auto 0', cursor: 'pointer'}} icon={faVolumeXmark} size="2x" onClick={() => playingCallback(true)}/>
    )
}

export default VolumeButton