import React from "react"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import AnimatedButton from "./AnimatedButton"

const DownloadButton = ({ count }) => {

    return(
        <div className="container-download">
            <AnimatedButton iconClass={faDownload}/>
            <span><b>{count}</b></span>
        </div>
    )
}

export default DownloadButton