import { ref, child, get } from "firebase/database";

export const getArragements = async (db) => {
    const snapshot = await get(child(ref(db), `arragements`)).catch(err => {
        console.error(err)
    });

    return snapshot.val()
}

export const getArragement = async (db, arragementsId) => {
    const snapshot = await get(child(ref(db), `arragements/${arragementsId}`)).catch(err => {
        console.error(err)
    });
    
    return snapshot.val()
}