import DownloadButton from "./DownloadButton"
import VolumeButton from "./VolumeButton"

const SectionInfo = ({ elem }) => {
    return(
        <div className='container-footer'>
            <VolumeButton/>
            <div className='container-meta'>
                <h4>{elem.name} - {elem.author}</h4>
                <span>{elem.instrumentation}</span>
            </div>
            <DownloadButton count={elem.downloadCount}/>
        </div>
    )
}

export default SectionInfo