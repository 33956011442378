import { useEffect, useState } from "react"
import SectionInfo from "./SectionInfo"
import { FILTER } from "../defaults"

const options = {
    rootMargin: '0px',
    threshold: 0.4
}
const removeClass = (el) => {
    if (el.classList.contains('is-visible')) {
        el.classList.remove('is-visible')
    }
}

const FiniteScroll = ({ filter, list }) => {

    const [index, setIndex] = useState(0)
    const [filtered, setFiltered] = useState([])
    const addClass = (el) => {
        if (!el.classList.contains('is-visible')) {
            setIndex(el.dataset.observer)
            el.classList.add('is-visible')
        }
    }
    useEffect(() => {
        if(filter === FILTER.ALL) {
            setFiltered(list)
        } else {
            setFiltered(list.filter(obj => obj.category === filter))
        }
    }, [filter, list])
    useEffect(() => {
        if(filtered) {
            const doThings = (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        addClass(entry.target)
                    } else {
                        removeClass(entry.target)
                    }
                })
            }    
            const targets = document.querySelectorAll('[data-observer]')
            const observer = new IntersectionObserver(doThings, options)
            targets.forEach(target => {
                observer.observe(target)
            })
        }
    }, [filtered])

    return(
        <div className='main'>
            {filtered?.length > 0 ? <>
                <div className='container'>
                    {filtered.map((_,i) => <div key={i} className="section" data-observer={i}></div>)}
                </div>
                <SectionInfo elem={filtered[index]}/></> : 
                <p>Actualmente no hay arreglos disponibles para {FILTER.DISPLAY[filter].toLowerCase()}.</p>
            }
        </div>
    )
}

export default FiniteScroll